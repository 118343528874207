<p-menu [popup]="true">
  <ng-template
    let-item
    pTemplate
    type="itemTemplate">
    <div
      class="flex size-full cursor-pointer select-none items-center gap-2 p-2 {{ item.badgeStyleClass }}"
      [class.pointer-events-none]="!!item.disable"
      [tooltipPosition]="item.tooltipPosition"
      [pTooltip]="item.tooltip">
      <svg-icon
        size="lg"
        class="w-5 shrink-0 {{ item.iconClass }}"
        [key]="item.icon"></svg-icon>
      <span class="text-sm">{{ item.label }}</span>
    </div>
  </ng-template>
</p-menu>
