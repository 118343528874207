import { AsyncPipe } from '@angular/common';
import { Component, inject, viewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService as PrimeNgConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';

import { RootMenuComponent } from './root-menu/root-menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  standalone: true,
  providers: [DialogService, PrimeNgConfirmationService, MessageService, TranslateService],
  imports: [AsyncPipe, ConfirmDialogModule, MenuModule, RootMenuComponent, RouterOutlet, ToastModule],
})
export class RootComponent {
  readonly rootMenu = viewChild.required(RootMenuComponent);

  readonly translate = inject(TranslateService);
  readonly primeNgConfig = inject(PrimeNGConfig);
}
